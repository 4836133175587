import SEO from "@components/seo";
import homepageData from "@data/homepages/home-page.json";
import HomeContainer from "./home/Container";

const Home = () => {
    return (
        <>
            <SEO pageTitle="Trang Chủ - AdsCivil BIM HUB" />
            <HomeContainer />
        </>
    );
};

export default Home;
